import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { edwinGroupName } from '../WatchListGroupName';
import KbarTrustQuote from '../quote/KbarTrustQuote';
import { sortBy } from 'lodash';
import { proxy, useSnapshot } from 'valtio';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { useMount } from 'react-use';
import { edwin_store } from '../../edwin_store';
import { css } from '@emotion/react';
import { Button } from '@mantine/core';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
export const useBig2DataStore = proxy({
    sortKey: 'none',
    orderKey: 'desc',
});
const Big2ActionButton = memo(function Big2ActionButton(props) {
    return (<Button css={css `
          width: 72px;
          height: 28px;
          font-size: 13px;
          font-weight: 500;
          background-color: ${props.color};
          &:hover {
            background-color: ${props.color};
            border: 1px solid #acacac;
          }
        `} 
    //color={props.color}
    variant='filled' radius='md' onClick={() => {
            edwin_store.big2ActiveType = props.action;
        }}>
        {props.children}
      </Button>);
});
const Big2SelectButton = memo(function Big2SelectButton(props) {
    const width = props.width ?? 72;
    return (<Button css={css `
          width: ${width}px;
          height: 28px;
          font-size: 13px;
          font-weight: 500;
          background-color: ${props.color};
          &:hover {
            background-color: ${props.color};
            border: 1px solid #acacac;
          }
        `} radius='md' variant='filled' 
    //color={props.color}
    onClick={() => props.storeAct()}>
        {props.children}
      </Button>);
});
export const Big2Content = memo(function TrustContent(props) {
    const watchList = useSymbolWatchList(edwinGroupName);
    const state = useSnapshot(useBig2DataStore);
    const edwinState = useSnapshot(edwin_store);
    const trustValue = props.quoteTrustValue;
    const foreignerValue = props.quoteForeignerValue;
    const foreignerData = props.foreignerData;
    const trustData = props.trustData;
    const upDate = edwinState.big3Date.format('YYYY-MM-DD');
    const load = (trustValue.length === trustData?.length && trustData?.length !== 0) ||
        (foreignerValue.length === foreignerData?.length && foreignerData?.length !== 0);
    //----------------------------------------------------------------
    //單一選擇
    const foreignerNewData = foreignerValue.map(s => {
        const matchingData = foreignerData.find(item => item.symbol === s.symbol);
        if (matchingData) {
            return {
                signalr: s,
                big3: matchingData,
            };
        }
        else {
            return {
                signalr: s,
                big3: {
                    symbol: '',
                    open: 0,
                    high: 0,
                    low: 0,
                    close: 0,
                    volume: 0,
                    amount: 0,
                    total_volume: 0,
                    diff_volume: 0,
                    diff_volume_percentage: 0,
                },
            };
        }
    });
    const trustNewData = trustValue.map(s => {
        const matchingData = trustData.find(item => item.symbol === s.symbol);
        if (matchingData) {
            return {
                signalr: s,
                big3: matchingData,
            };
        }
        else {
            return {
                signalr: s,
                big3: {
                    symbol: '',
                    open: 0,
                    high: 0,
                    low: 0,
                    close: 0,
                    volume: 0,
                    amount: 0,
                    total_volume: 0,
                    diff_volume: 0,
                    diff_volume_percentage: 0,
                },
            };
        }
    });
    //先過外資買超很少的 1千萬金額
    const filterForeignerData = foreignerNewData.filter(val => Math.abs(val.big3.diff_volume) * val.big3.close * 1000 > 1e7);
    //先過投信買超很少的 1千萬金額
    const filterTrustData = trustNewData.filter(val => Math.abs(val.big3.diff_volume) * val.big3.close * 1000 > 1e7);
    //拿到上述兩者故別資料後 將外資與投信的買賣超合併..
    const mergeNewData = filterForeignerData.map(s => {
        const matchingData = filterTrustData.find(item => item.big3.symbol === s.big3.symbol);
        if (matchingData) {
            return {
                signalr: s.signalr,
                big3: {
                    symbol: s.big3.symbol,
                    open: s.big3.open,
                    high: s.big3.high,
                    low: s.big3.low,
                    close: s.big3.close,
                    volume: s.big3.volume,
                    amount: s.big3.amount,
                    total_volume: s.big3.total_volume,
                    diff_volume: s.big3.diff_volume + matchingData.big3.diff_volume,
                    diff_volume_percentage: s.big3.diff_volume_percentage + matchingData.big3.diff_volume_percentage,
                },
            };
        }
        else {
            return {
                signalr: s.signalr,
                big3: {
                    symbol: '',
                    open: 0,
                    high: 0,
                    low: 0,
                    close: 0,
                    volume: 0,
                    amount: 0,
                    total_volume: 0,
                    diff_volume: 0,
                    diff_volume_percentage: 0,
                },
            };
        }
    });
    //先過外資與投信買超很少的 1千萬金額
    const filterMergeData = mergeNewData.filter(val => Math.abs(val.big3.diff_volume) * val.big3.close * 1000 > 1e7);
    useEffect(() => {
        edwin_store.big2Symbol = filterMergeData.map(s => s.big3.symbol);
    }, [edwinState.big2Type, edwinState.big2ActiveType]);
    //依據點選的三大法人類別顯示對應商品
    const resultNewData = edwinState.big2Type === 'foreigner'
        ? filterForeignerData
        : edwinState.big2Type === 'trust'
            ? filterTrustData
            : filterMergeData;
    //這邊是做另一種資料排序
    const sortData = () => {
        if (state.sortKey === 'diff' && state.orderKey === 'asc') {
            return sortBy(resultNewData, datum => datum.big3.diff_volume * datum.big3.close);
        }
        if (state.sortKey === 'diff' && state.orderKey === 'desc') {
            return sortBy(resultNewData, datum => datum.big3.diff_volume * datum.big3.close).reverse();
        }
        if (state.sortKey === 'diffPercent' && state.orderKey === 'asc') {
            return sortBy(resultNewData, datum => datum.big3.diff_volume_percentage);
        }
        if (state.sortKey === 'diffPercent' && state.orderKey === 'desc') {
            return sortBy(resultNewData, datum => datum.big3.diff_volume_percentage).reverse();
        }
        return resultNewData;
    };
    //----------------------------------------------------------------
    //第一次預設買賣超金額排序
    useMount(() => {
        useBig2DataStore.sortKey = 'diff';
        useSortSignalrDataStore.sortKey = 'none';
    });
    useEffect(() => {
        edwinState.big2ActiveType === 'buy'
            ? (useBig2DataStore.orderKey = 'desc')
            : (useBig2DataStore.orderKey = 'asc');
    }, [edwinState.big2ActiveType]);
    return (<styleds.container>
      <styleds.big2Selecter>
        <Big2SelectButton storeAct={() => (edwin_store.big2Type = 'foreigner')} color={edwinState.big2Type === 'foreigner' ? '#e29134' : '#353a3f'}>
          外資
        </Big2SelectButton>
        <Big2SelectButton storeAct={() => (edwin_store.big2Type = 'trust')} color={edwinState.big2Type === 'trust' ? '#e29134' : '#353a3f'}>
          投信
        </Big2SelectButton>
        <Big2SelectButton storeAct={() => (edwin_store.big2Type = 'all')} color={edwinState.big2Type === 'all' ? '#e29134' : '#353a3f'} width={132}>
          外資與投信同步
        </Big2SelectButton>
      </styleds.big2Selecter>
      <div css={css `
          width: 100%;
          height: 1px;
          background-color: #565656;
        `}/>
      <styleds.actionSelecter>
        <Big2ActionButton action='buy' color={edwinState.big2ActiveType === 'buy' ? '#ce423a' : '#353a3f'}>
          買超
        </Big2ActionButton>
        <Big2ActionButton action='sell' color={edwinState.big2ActiveType === 'sell' ? '#529c4f' : '#353a3f'}>
          賣超
        </Big2ActionButton>
        <styleds.updateContent>資料日期: {upDate}</styleds.updateContent>
      </styleds.actionSelecter>
      <styleds.header>
        <KbarTrustQuote.header />
      </styleds.header>
      <styleds.body>
        {load &&
            sortData()?.map((quote, index) => {
                return (<KbarTrustQuote.body symbolWatchList={watchList} key={index} data={quote.big3} quote={quote.signalr}/>);
            })}
        {!load && <LoadCard.Loading />}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    big2Selecter: styled.div `
    ${fill_horizontal_cross_center};
    height: 36px;
    gap: 8px;
  `,
    actionSelecter: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    width: 100%;
    height: 36px;
  `,
    updateContent: styled.div `
    ${fill_horizontal_cross_center};
    width: 160px;
    height: 18px;
    line-height: 18px;
    font-size: 13px;
  `,
    header: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 36px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 2px;
    height: calc(100% - 126px);
  `,
};
