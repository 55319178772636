import { css, keyframes } from '@emotion/react';
import { Tooltip } from '@mantine/core';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { stockDecimal } from '~/modules/new-version-counsultant/Quote/stockDecimal';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
//import './StockKbarBox.css'
export const stockKbarBoxStore = proxy({
    fontSize: 13,
});
const ToolTips = memo(function ToolTips(props) {
    const value = props.quote;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const symbol = value.symbol ?? '';
    const close = value.close ?? 0;
    const open = value.open ?? 0;
    const high = value.high ?? 0;
    const low = value.low ?? 0;
    const volume = value.volume ?? 0;
    const prevRef = value.prevRef ?? 0;
    const quoteChanges = processQuoteToReadable(value);
    const changeSymbol = quoteChanges.closeChange > 0 ? '+' : '';
    const autoDecimal = (quoteValue) => {
        return quoteValue.toFixed(stockDecimal(quoteValue));
    };
    const quoteColor = (quoteValue) => {
        if (quoteValue > prevRef)
            return '#ff3232';
        if (quoteValue < prevRef)
            return '#0dc316';
        if (quoteValue > prevRef)
            return '#ffff22';
        return '#ffffff';
    };
    return (<div css={css `
        ${fill_vertical_cross_center};
        align-items: start;
        height: auto;
        width: 88px;
        font-size: 12px;
        line-height: 15px;
        transform: scale(0.9);
        color: #f1f1f1;
        gap: 1px;
        & > *:nth-of-type(1) > * {
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
          line-height: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & > *:nth-of-type(2) > * {
          color: ${quoteColor(close)};
        }
        & > *:nth-of-type(3) > * {
          color: ${quoteColor(close)};
        }
        & > *:nth-of-type(4) > * {
          color: ${quoteColor(close)};
        }
        & > *:nth-of-type(5) > * {
          color: ${quoteColor(open)};
        }
        & > *:nth-of-type(6) > * {
          color: ${quoteColor(high)};
        }
        & > *:nth-of-type(7) > * {
          color: ${quoteColor(low)};
        }
        & > *:nth-of-type(8) > * {
          color: #ffff22;
        }
      `}>
      <div>
        <span>{dictionary[symbol]}</span>
        <span>{symbol}</span>
      </div>
      <div>
        成交:
        <span> {autoDecimal(close)}</span>
      </div>
      <div>
        漲跌:
        <span>
          {' '}
          {changeSymbol}
          {quoteChanges.closeChange}
        </span>
      </div>
      <div>
        幅度:
        <span>
          {' '}
          {changeSymbol}
          {quoteChanges.closeChangePercent}%
        </span>
      </div>
      <div>
        開盤:
        <span> {autoDecimal(open)}</span>
      </div>
      <div>
        最高:
        <span> {autoDecimal(high)}</span>
      </div>
      <div>
        最低:
        <span> {autoDecimal(low)}</span>
      </div>
      <div>
        總量:
        <span> {volume}</span>
      </div>
    </div>);
});
export const StockKbar = memo(function StockKbar(props) {
    const state = useSnapshot(stockKbarBoxStore);
    /** 由stockKbarBoxStore 控制K棒寬度確保商品文字寬度與K棒一致 */
    const kbarWidth = state.fontSize;
    /** Signalr */
    const value = props.quote;
    /** 漲停價 */
    const limitUp = value?.limitUpPrice ?? 0;
    /** 跌停價 */
    const limitDn = value?.limitDownPrice ?? 0;
    const close = value?.close ?? 0;
    const open = value?.open ?? 0;
    const high = value?.high ?? 0;
    const low = value?.low ?? 0;
    /** 最高價離實體K上緣的距離 */
    const base1 = close > open ? close : open;
    /** 最低價離實體K上緣的距離 */
    const base2 = close <= open ? close : open;
    /** K棒最大高度;漲停倒跌的距離 */
    const range = limitUp - limitDn;
    /** 實體K高度 */
    const bar = Math.abs(close - open);
    /** 實體K顏色 */
    const barColor = close > open ? '#ff3232' : close < open ? '#0dc316' : '#ffff22';
    //K棒數值
    const topAreaValue = (limitUp - high) / range;
    const upBorderValue = (high - base1) / range;
    const barValue = bar / range === 0 ? 0.01 : bar / range;
    const dnBorderValue = (base2 - low) / range;
    const bottomValue = (low - limitDn) / range;
    //K棒數值 css使用
    const kbarTopArea = (topAreaValue * 100).toFixed(3);
    const kbarUpBorder = (upBorderValue * 100).toFixed(3);
    const kbar = (barValue * 100).toFixed(3);
    const kbarDnBorder = (dnBorderValue * 100).toFixed(3);
    const kbarBottomArea = (bottomValue * 100).toFixed(3);
    return (<div 
    //className='container'
    css={css `
          ${classes.container};
          width: ${kbarWidth}px;
        `}>
        <Tooltip position='left' withArrow={false} label={<ToolTips quote={value}/>}>
          {/* 這邊是最高 最低 */}
          <div 
    //className='range'
    css={css `
              ${classes.range}
              grid-template-rows: ${kbarTopArea}% ${kbarUpBorder}% ${kbar}% ${kbarDnBorder}% ${kbarBottomArea}%;
            `}>
            <div css={classes.kbar.empty}/>
            <div css={classes.kbar.border}/>
            <div css={css `
                ${classes.kbar.bar};
                background-color: ${barColor};
              `}/>
            <div css={classes.kbar.border}/>
            <div css={classes.kbar.empty}/>
          </div>
        </Tooltip>
      </div>);
});
const fadeIn = keyframes `
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;
const classes = {
    container: css `
    ${fill_vertical_all_center};
    height: 100%;
    &:hover {
      background: linear-gradient(#66666633, #66666666, #66666633);
    }
    flex-shrink: 0;
    & > .mantine-g0lxdh {
      background-color: #111111ee;
      border: 1px solid #787878;
      padding: 0;
    }
  `,
    range: css `
    width: 100%;
    height: 83.3%;
    display: grid;
    animation: ${fadeIn} 0.8s linear forwards;
  `,
    kbar: {
        empty: css `
      width: 1.4px;
      height: 100%;
    `,
        border: css `
      width: 1.4px;
      height: 100%;
      background-color: #737375;
      margin: 0 auto;
    `,
        bar: css `
      width: 100%;
      height: 100%;
      border-radius: 0.4px;
      margin: 0 auto;
    `,
    },
};
