import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const freeman_cost = createIndicator({
    displayName: '成本線',
    id: 'freeman-cost',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const dayTradeAvgPrice = this.dayAvgPrice(2);
            return [dayTradeAvgPrice];
        },
    },
    metainfo: {
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#c2c23e',
                },
            },
        },
        styles: {
            plot_0: {
                title: '成本線',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
