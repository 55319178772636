import { proxy } from 'valtio';
import { FuiUserAvatar } from '~/pages/heineken_template/components/FuiUserAvatar';
import dayAPI from '~/utils/dayAPI';
export const edwin_store = proxy({
    //sidepan1 模式
    mode: 'screener',
    userAvatar: new FuiUserAvatar(),
    displayType: 'card',
    //產業模式 預設熱門|相關產業
    industryType: 'default',
    //選股類別
    screenerType: 'realTime',
    //歷史選股日期
    screenerDate: '',
    //選股標記
    screenerMarkDate: '',
    //法人類別
    big2Type: 'foreigner',
    //法人動作
    big2ActiveType: 'buy',
    //法人日期 若最新的日期沒資料,會塞入前一天的日期
    big3Date: dayAPI(),
    //這邊是在法人部分merge data後,將結果傳入
    big2Symbol: [''],
    //quoteValueContainer: [] as Signalr.ValueOfOHLC[],
});
