import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import YouTube from 'react-youtube';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { proxy, useSnapshot } from 'valtio';
import useSWR from 'swr-0-5-6';
//youtuber v3 api key
//AIzaSyC3JHLcbzxI5krYrhNs7zzudMGCNNjhGdc
//AIzaSyDlnN4fwntb902t5hwTw-hSwqGyickmVqQ
//EBC東森財經股市24小時直播 video id
//LbS-xQ67fos
export const useGetYoutubeData = (channelID) => {
    const apiUrl = 'https://cms.futures-op.com/api/youtube-videos?filters%5Bchannel_id%5D%5B$eq%5D=' + channelID;
    const res = useSWR(apiUrl, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
const opts = {
    playerVars: {
        autoplay: 0,
    },
};
export const freemanStore = proxy({
    pageType: 'list',
    currentChannelName: 'freeman',
    currentVideoId: '',
    popularSymbols: [],
    tw50Symbols: [],
    highPriceSymbols: [],
});
const ChannelButton = memo(function ChannelButton(props) {
    const state = useSnapshot(freemanStore);
    const selected = state.currentChannelName === props.channelName;
    const pageType = props.pageType;
    const channelName = props.channelName;
    const videoID = props.videoID;
    return (<span css={css `
        ${classes.column.channelName};
        border-bottom: 1.6px solid ${selected ? '#ff0000' : 'transparent'};
        font-size: ${selected ? '12.6' : '12.4'}px;
        color: ${selected ? '#f1f1f1' : '#b0b0b0'};
      `} onClick={() => {
            freemanStore.pageType = pageType;
            freemanStore.currentChannelName = channelName;
            if (videoID !== undefined) {
                freemanStore.currentVideoId = videoID;
            }
        }}>
      {props.children}
    </span>);
});
export const VideoContent = memo(function VideoContent() {
    const state = useSnapshot(freemanStore);
    const futuresaiVideoDatas = useGetYoutubeData('UCxn_ZSz7lavsYxzlJKNr-LA').data;
    const freemaniVideoDatas = useGetYoutubeData('UCKYsEcghAPJvINpq7wJszOA').data;
    //const uanalyzeVideoDatas = useGetYoutubeData('UCmBQRXvXkeYVBT8nZykMfWw').data
    const futuresVideo = futuresaiVideoDatas?.data.map(s => s.attributes);
    const freemanVideo = freemaniVideoDatas?.data.map(s => s.attributes);
    //const uanalyzeVideo = uanalyzeVideoDatas?.data.map(s => s.attributes)
    const dataOfChannelType = () => {
        //假設有多種 作者頻道
        /** 期天 */
        if (state.currentChannelName === 'futuresai') {
            return futuresVideo?.flatMap(s => s.entry);
        }
        /** 自由人 */
        if (state.currentChannelName === 'freeman') {
            return freemanVideo?.flatMap(s => s.entry);
        }
        /** 投資優我罩 */
        // if (state.currentChannelName === 'uanalyze') {
        //   return uanalyzeVideo?.flatMap(s => s.entry)
        // }
        // /** 非凡 */
        // if (state.currentChannelName === 'ustv') {
        //   return ustvVideo?.flatMap(s => s.entry)
        // }
        return futuresVideo?.flatMap(s => s.entry);
    };
    //預設的影片id
    const defaultVideoID = freemanVideo?.[0].entry?.[0].video_id ?? '';
    useEffect(() => {
        if (defaultVideoID === '') {
            freemanStore.currentVideoId = defaultVideoID;
        }
    }, [defaultVideoID]);
    return (<div css={classes.container}>
      {/* 左側控制欄 */}
      <div css={classes.column.container}>
        <img src={'./freeman/yt_logo_rgb_dark.png'} css={classes.youtubeIcon}/>
        <ChannelButton pageType='player' channelName='player' videoID={state.currentVideoId}>
          影片播放器
        </ChannelButton>
        <ChannelButton pageType='list' channelName='freeman'>
          自由人
        </ChannelButton>
        <ChannelButton pageType='list' channelName='futuresai'>
          期天大勝
        </ChannelButton>
        {/* <ChannelButton
          pageType='list'
          channelName='ustv'
        >
          非凡Morning Call
        </ChannelButton> */}
        {/* <ChannelButton
          pageType='list'
          channelName='uanalyze'
        >
          投資優我罩
        </ChannelButton> */}
        <ChannelButton pageType='player' videoID='LbS-xQ67fos' channelName='ebc'>
          東森財經直播
        </ChannelButton>
      </div>
      {/* 播放器 */}
      {state.pageType === 'player' && (<div css={classes.videoPlayer.container}>
          <YouTube videoId={state.currentVideoId} opts={opts}/>
        </div>)}

      {state.pageType === 'list' && (<div css={classes.videoList.container}>
          {dataOfChannelType()?.map(video => (<div css={classes.card.container} onClick={() => {
                    freemanStore.currentVideoId = video.video_id;
                    freemanStore.pageType = 'player';
                    freemanStore.currentChannelName = 'player';
                }} key={video.video_id}>
              <img css={classes.card.images} src={video.media_thumbnail_url}/>
              <span css={classes.card.titleContainer}>
                <span css={classes.card.title}> {video.title}</span>
              </span>
            </div>))}
        </div>)}
    </div>);
});
const classes = {
    container: css `
    display: grid;
    grid-template-columns: 108px 1fr;
    width: 100%;
    height: 100%;
  `,
    youtubeIcon: css `
    width: 90px;
    padding: 6px 0;
    margin-bottom: 6px;
  `,
    column: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      padding: 2px;
      background-color: #212020;
      border-radius: 4px;
    `,
        channelName: css `
      ${fill_horizontal_cross_center};
      height: 24px;
      font-weight: 500;
      cursor: pointer;
      padding: 0 2px;
      &:hover {
        border-bottom: 1.6px solid #787878;
      }
      transition: 0.3s;
    `,
    },
    videoPlayer: {
        container: css `
      width: 100%;
      height: 100%;
      & > * {
        width: 100%;
        height: 100%;
      }
      & > * > iframe {
        width: 100%;
        height: 100%;
      }
    `,
    },
    videoList: {
        container: css `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      gap: 6px;
      padding: 2px;
    `,
    },
    card: {
        container: css `
      ${fill_horizontal_all_center};
      display: grid;
      grid-template-columns: 100px 1fr;
      width: 100%;
      max-height: 72px;
      border-radius: 4px;
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        background-color: #282828;
      }
    `,
        images: css `
      width: 100%;
      height: 100%;
      max-height: 72px;
    `,
        titleContainer: css `
      ${fill_vertical_cross_center};
      max-height: 60px;
    `,
        title: css `
      width: 100%;
      height: auto;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #f1f1f1;
      padding: 0 2px;
      overflow: hidden;
    `,
        description: css `
      width: 100%;
      height: 100%;
      font-size: 12px;
      line-height: 14px;
      color: #cccccc;
      white-space: pre-wrap;
      overflow: hidden;
      padding: 0 2px;
      transform: scale(0.94);
      margin-left: -6%;
    `,
        date: css `
      ${fill_vertical_cross_center};
      align-items: start;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #f1f1f1;
      & > * {
        border-radius: 6px;
        background-color: #252525;
        transform: scale(0.8);
        padding: 1px 4px;
      }
    `,
    },
};
// const apiKey = 'AIzaSyC3JHLcbzxI5krYrhNs7zzudMGCNNjhGdc'
// const getChannelDataUrl = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=UCKYsEcghAPJvINpq7wJszOA&part=snippet&type=video&order=date`
// const apiListDataUrl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=PLSg6_lakxpXE4MVtJY4Og7JKXKdg7O1DP&key=${apiKey}`
// const opts = {
//   playerVars: {
//     autoplay: 0,
//   },
// }
// useEffect(() => {
//   axios
//     .get(apiUrl)
//     .then(response => {
//       setVideos(response.data.items)
//       if (response.data.items.length > 0) {
//         setCurrentVideo(response.data.items[0])
//       }
//     })
//     .catch(error => {
//       console.error('Error fetching data:', error)
//     })
// }, [])
