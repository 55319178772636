import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const freeman_diff = createIndicator({
    id: 'freeman-diff',
    displayName: '多空差額',
    constructorScope: {
        init(context, inputcallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#bs_indicator_acc';
            context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            const acc = this.PineJS.Std.high(this._context);
            const bar = this._context.new_var(acc);
            bar.get(5);
            const diff = bar.get(0) - bar.get(1);
            const colorIndex = diff >= 0 ? 0 : 1;
            return [acc, diff * 3, colorIndex];
        },
    },
    metainfo: {
        precision: 2,
        plots: [
            {
                id: 'acc',
                type: 'line',
            },
            {
                id: 'bar',
                type: 'line',
            },
            {
                id: 'barPalette',
                type: 'colorer',
                target: 'bar',
                palette: 'barPalette',
            },
        ],
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        name: '能量',
                    },
                    1: {
                        name: '能量',
                    },
                },
            },
        },
        defaults: {
            styles: {
                acc: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
                bar: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
            },
            palettes: {
                barPalette: {
                    colors: {
                        0: {
                            color: '#cc2222',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#22cc22',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            acc: {
                title: '成本線',
                histogramBase: 0,
                joinPoints: !1,
            },
            bar: {
                title: '差額',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
