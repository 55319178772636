import styled from '@emotion/styled';
import { memo } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { edwinGroupName } from '../WatchListGroupName';
import { sortBy } from 'lodash';
import { proxy, useSnapshot } from 'valtio';
import { useMount } from 'react-use';
import KbarTurnoverQuote from '../quote/KbarTurnoverQuote';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import dayAPI from '~/utils/dayAPI';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
export const turnoverDataStore = proxy({
    sortKey: 'none',
    orderKey: 'desc',
});
export const TurnoverContent = memo(function TurnoverContent(props) {
    const watchList = useSymbolWatchList(edwinGroupName);
    const value = props.quoteValue;
    const data = props.data;
    const state = useSnapshot(turnoverDataStore);
    const upDate = dayAPI(data.map(s => s.datetime)[0]).format('YYYY-MM-DD HH:mm:ss');
    const newData = value.map(s => {
        const matchingData = data.find(item => item.symbol === s.symbol);
        if (matchingData) {
            return {
                signalr: s,
                turnoverRate: matchingData,
            };
        }
        else {
            return {
                signalr: s,
                turnoverRate: {
                    date: '',
                    datetime: '',
                    symbol: '',
                    open: 0,
                    high: 0,
                    low: 0,
                    close: 0,
                    volume: 0,
                    issued_shares: 0,
                    avg_turnover_rate: 0,
                    turnover_rate: 0,
                },
            };
        }
    });
    //週轉率排序
    //這邊是做另一種資料排序
    const sortData = () => {
        if (state.sortKey === 'turnover_rate' && state.orderKey === 'asc') {
            return sortBy(newData, datum => datum.turnoverRate.turnover_rate);
        }
        if (state.sortKey === 'turnover_rate' && state.orderKey === 'desc') {
            return sortBy(newData, datum => datum.turnoverRate.turnover_rate).reverse();
        }
        if (state.sortKey === 'avg_turnover_rate' && state.orderKey === 'asc') {
            return sortBy(newData, datum => datum.turnoverRate.avg_turnover_rate);
        }
        if (state.sortKey === 'avg_turnover_rate' && state.orderKey === 'desc') {
            return sortBy(newData, datum => datum.turnoverRate.avg_turnover_rate).reverse();
        }
        return newData;
    };
    //第一次預設買賣超金額排序
    useMount(() => {
        turnoverDataStore.sortKey = 'turnover_rate';
        useSortSignalrDataStore.sortKey = 'none';
    });
    return (<styleds.container>
      <styleds.updateContent>資料日期: {upDate}</styleds.updateContent>
      <styleds.header>
        <KbarTurnoverQuote.header />
      </styleds.header>
      <styleds.body>
        {sortData()?.map((quote, index) => {
            return (<KbarTurnoverQuote.body symbolWatchList={watchList} key={index} data={quote.turnoverRate} quote={quote.signalr}/>);
        })}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    updateContent: styled.div `
    ${fill_horizontal_cross_center};
    height: 18px;
    line-height: 18px;
    font-size: 13px;
  `,
    header: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 36px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 2px;
    heigth: calc(100% - 72px);
  `,
};
